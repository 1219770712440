<template>
  <div class="container mb-3">
    <BaseTitle v-if="title">{{ title }}</BaseTitle>
    <template v-if="shops.length">
      <div class="shops-grid">
        <ShopItem
          v-for="shop in shops"
          :key="shop.id"
          :shop="shop"
          class="shop-item"
        />
      </div>
      <CenteredContent v-if="showLoadMoreButton" class="mt-3">
        <BaseButton @click="loadMore">{{ $t("shops.load-more") }}</BaseButton>
      </CenteredContent>
    </template>
    <BaseText v-else-if="input?.search" class="bold centered-block">
      {{ $t("search.no-results") }}
    </BaseText>
  </div>
</template>

<script setup lang="ts">
import {
  useShopsQuery,
  type ShopsInput,
  type PaginationInput,
} from "~/graphql/generated";

const props = defineProps<{
  title?: string;
  input?: ShopsInput;
  pagination: PaginationInput;
  showLoadMore?: boolean;
}>();

// Get the users location (if set)
const location = useLocationState();

// Mark this as reactive, so the query will re-run when the value changes
const shopsInput = toReactive(props);
const pagination = toReactive(props.pagination);

const variables = computed(() => {
  return {
    shopsInput: {
      ...shopsInput.input,
      ...(location.value && {
        point: {
          latitude: location.value.lat,
          longitude: location.value.long,
        },
      }),
    },
    pagination,
    limitPlusOffset: pagination.limit + pagination.offset,
  };
});

const { data } = await useShopsQuery({
  variables,
});

const shops = ref(data.value?.shops ?? []);

const showLoadMoreButton = computed(
  () =>
    props.showLoadMore && data.value?.nextShop && data.value.nextShop.length > 0
);
// When the data changes, check if we need to append or replace the data (in case of the load more button press)
watch(
  data,
  () => {
    if (pagination.offset === 0) {
      shops.value = data?.value?.shops ?? [];
    } else {
      shops.value = shops.value.concat(data?.value?.shops ?? []);
    }
  },
  { immediate: true }
);

// Watch the input and reset the pagination when it changes
watch(
  () => props.input,
  () => {
    pagination.offset = 0;
  }
);

const loadMore = () => {
  pagination.offset = shops.value.length;
};
</script>

<style lang="scss" scoped>
.shops-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  @include for-extra-small-screen {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
